a#CybotCookiebotDialogPoweredbyCybot,div#CybotCookiebotDialogPoweredByText,
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none !important;
}
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton{
  background-color: $color-primary !important;
  border-color: $color-primary !important;
  color: #ffffff !important;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid $color-primary !important;
  color: $color-primary !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover,
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a{
  color: $color-primary !important;
}
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 2px solid $color-primary !important;
}
#CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: $color-primary !important;
}