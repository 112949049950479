h1 { font-size:2.85rem; line-height: 3.4rem; }
h2 { font-size:2.3rem; line-height:3rem; }
h3 { font-size:1.8rem; line-height:2.5rem; }

a:hover, a:focus,
.tab-style-02 .nav-tabs > li.nav-item > a.nav-link.active {
  color: $color-primary;
}

.navbar .navbar-nav .nav-link {
  margin: 0 16px;
}

.bg-very-light-pink{
  background-color: #f7efed;
}

.background-position-left-bottom {
    background-position: left center;
}

.page-title-large h1 {
    text-transform: uppercase;
}

